<template>
  <div
    class="d-flex justify-content-between flex-row align-center top-panel mx-2"
  >
    <div class="d-flex align-center">
      <h2>{{ item.conveniq_name }}</h2>
    </div>
  </div>
  <base-tabs
    v-if="tabsList.length > 1"
    v-model="activeTab"
    :tabsList="tabsList"
    :instanceId="item.conveniq_id"
    @change="onTabChange"
  >
  </base-tabs>
  <div
    class="tabs-content d-flex flex-column"
    style="height: 85vh"
    id="cardDetailMonitoring"
  >
    <div
      v-if="rowData.length > 0"
      class="d-flex flex-column h-100 w-100"
      style="overflow: auto"
    >
      <div class="table">
        <div class="row header">
          <div class="cell">{{ $t("monitoring.labels.indicator") }}</div>
          <div class="cell">{{ $t("monitoring.labels.status") }}</div>
        </div>
        <div v-for="(group, index) in rowData" :key="index" class="row-group">
          <div class="row main-row" @click.stop="toggleGroup(group)">
            <div class="cell">
              <div
                class="d-flex justify-content-center align-items-center mr-2"
              >
                <svg
                  class="fill-none stroke-grey transition-transform"
                  :class="[group.showSubrows ? 'tranform0' : 'tranform90']"
                  height="24px"
                  width="24px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke-width="1.5"
                >
                  <path
                    d="M9 18C9 18 15 13.5811 15 12C15 10.4188 9 6 9 6"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>

              <span v-if="group.id">{{
                $t("monitoring.labels." + group.id)
              }}</span>
              <span v-if="group.name" class="ml-2">{{ group.name }}</span>
            </div>
            <div class="cell">
              <boolRound :value="group.status"></boolRound>
            </div>
          </div>
          <div
            v-if="group.showSubrows && group.indicators?.length > 0"
            class="subrows"
          >
            <div
              v-for="(item, ind2) in group.indicators"
              :key="ind2"
              class="subrow"
            >
              <div class="subcell">
                {{ $t("monitoring.labels." + item.id) }}
              </div>
              <div class="subcell">
                <boolRound :value="item.value"></boolRound>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="activeTab === 'Commands'">
      <ConveniqCommand :conveniq-id="item.conveniq_id" />
    </div>

    <div
      v-if="rowData.length < 1 && activeTab !== 'Commands'"
      class="d-flex flex-column h-100 w-100 justify-content-center align-items-center"
      style="overflow: auto"
    >
      <h4>{{ $t("lables.loading") }}</h4>
    </div>
    <spinner v-if="spinnerShow"></spinner>
  </div>
</template>
<script>
//import { hasProperty } from "@/helpers";
import EntityApi from "@/api/entityApi";
import boolRound from "@/pages/instancePages/boolRound.vue";
import ConveniqCommand from "@/pages/instancePages/ConveniqCommand.vue";
import BaseTabs from "@/components/BaseTabs.vue";

import Spinner from "../../components/Spinner.vue";
export default {
  name: "MonitoringCard",
  props: {
    item: {
      type: Object,
    },
    isModal: {
      type: Boolean,
      default: true,
    },
    refresh: {
      type: Boolean,
    },
  },
  inject: [],
  emits: ["closeModal"],
  components: {
    Spinner,
    BaseTabs,
    boolRound,
    ConveniqCommand,
  },
  data() {
    return {
      spinnerShow: false,
      activeTab: "Group indicators",

      rowData: [],

      tabList: [
        {
          id: 1,
          name: "group_indicators",
          label: "Group indicators",
          position: "tabs",
        },
        {
          id: 2,
          name: "events",
          label: "Events",
          position: "tabs",
        },
        {
          id: 3,
          name: "commands",
          label: "Commands",
          position: "tabs",
        },
      ],

      minutes: 5,
      timerAutoRefresh: null,
    };
  },
  watch: {},
  computed: {
    tabsList() {
      let tablist = [];

      if (this.tabList.length > 0) {
        this.tabList.forEach((element) => {
          tablist.push(element.label);
        });
      }

      return tablist;
    },
    activeTabEntity() {
      if (this.activeTab.length > 0) {
        const activeTabObject = Object.entries(this.tabList).find(
          (el) => el[1].label === this.activeTab
        );
        if (activeTabObject) {
          return activeTabObject[1];
        } else return null;
      } else return null;
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    onTabChange(tab) {
      console.log(tab);
      this.activeTab = null;
      this.activeTab = tab;

      this.getAutoData();
    },
    cukle() {
      this.timerAutoRefresh = setTimeout(() => {
        clearTimeout(this.timerAutoRefresh);
        this.timerAutoRefresh = null;

        this.loadData();
        this.cukle();
      }, this.minutes * 60 * 1000);
    },
    getAutoData() {
      if (this.refresh) {
        clearTimeout(this.timerAutoRefresh);
        this.timerAutoRefresh = null;

        this.loadData();
        this.cukle();
      } else {
        this.loadData();
      }
    },
    loadData() {
      if (this.activeTabEntity && this.activeTabEntity.name) {
        switch (this.activeTabEntity.name) {
          case "group_indicators":
            this.getIndicators();
            break;
          case "events":
            console.log("load data events");
            this.rowData = [];
            break;
          case "commands":
            console.log("load data commands");

            clearTimeout(this.timerAutoRefresh);
            this.timerAutoRefresh = null;

            this.rowData = [];
            break;
          default:
            break;
        }
      }
    },
    async getIndicators() {
      this.spinnerShow = true;

      let resp = await EntityApi.getIndicators(this.item.conveniq_id);
      if (resp && Array.isArray(resp) && resp.length > 0) {
        resp.map((row) => {
          row.showSubrows = false;
        });
        this.rowData = resp;
      }

      this.spinnerShow = false;
    },
    toggleGroup(group) {
      group.showSubrows = !group.showSubrows;
    },
    formatText(text) {
      let str = text;
      if (str.length > 0) {
        str = str.replace(/_/g, " ");

        if (str.length > 0) {
          str = str.charAt(0).toUpperCase() + str.slice(1);
        }
      }
      return str;
    },
  },
  created() {},
  mounted() {
    this.getAutoData();
  },
  beforeUnmount() {
    clearTimeout(this.timerAutoRefresh);
    this.timerAutoRefresh = null;

    this.closeModal();
  },
};
</script>
<style scoped>
.top-panel {
  padding-top: 6px;
  padding-left: 20px;
}

.item-id {
  font-size: 12px;
  color: rgb(175, 174, 174);
}

.show-scroll {
  overflow: auto;
}

.hide-scroll {
  overflow: hidden;
}

.table {
  display: grid;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.row-group {
  border-bottom: 1px solid #ddd;
}

.header {
  background-color: #6e6e6e79;
  color: white;
  font-weight: bold;
}
.header .cell {
  display: flex;
  text-transform: uppercase;
  justify-content: center;
}

.cell,
.subcell {
  padding: 15px;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
}

.main-row:hover {
  background-color: #f1f1f1;
}
.main-row .cell:first-child {
  font-weight: 600;
  font-size: 1.1rem;
}
.main-row .cell:last-child {
  justify-content: center;
}

.subrows {
  background-color: #f9f9f9;
}
.row {
  display: grid;
  grid-template-columns: 45% 55%;
  cursor: pointer;
}
.subrow {
  display: grid;
  grid-template-columns: 45% 55%;
  background-color: #f3f3f3;
}

.subrow .subcell {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}
.subrow .subcell:first-child {
  padding-left: 2rem;
}
.subrow .subcell:last-child {
  justify-content: center;
}

.row-group:last-child {
  border-bottom: none;
}

.transform-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
.tranform90 {
  transform: rotate(90deg);
}
.tranform0 {
  transform: rotate(270deg);
}
.stroke-grey {
  stroke: #c0c2cd;
}
.stroke-grey:hover {
  stroke: #519df5;
}
.fill-none {
  fill: none;
}
</style>
