<template>
  <div class="d-flex w-100 flex-column" style="flex: 1 0 auto">
    <div class="w-100 mb-2">
      <!-- style="padding: 10px 12px 12px 12px;" -->
      <settings
        :dates="false"
        :device="settingListDevice"
        :item="[]"
        @event-setting="confirmSetting"
      ></settings>
    </div>
    <div
      class="d-flex flex-column w-100"
      style="flex: 1 0 auto"
      id="cardMonitoring"
    >
      <base-card style="min-height: 100%" class="d-flex flex-column">
        <template v-slot:header>
          <div class="d-flex justify-content-between w-100">
            <h2>{{ $t("monitoring.title") }}</h2>

            <div class="d-flex justify-content-center align-items-center">
              <!-- <base-checkbox
                id="autoupd"
                class="d-flex justify-content-center"
                :value="autorefresh"
                :modelValue="autorefresh"
                @update:modelValue="autorefresh = $event"
                :trueValue="true"
                :falseValue="false"
              ></base-checkbox> -->

              <button
                class="v-btn v-theme--ORANGE_THEME bg-primary v-btn--density-default v-btn--size-default v-btn--variant-flat"
                @click="getAutoData()"
              >
                <i class="icon-spinner11" style="margin-right: 5px"></i>
                {{ $t(titleRefresh) }}
              </button>

              <span style="margin-left: 10px; font-size: 14px">
                ({{ lastUpdate }})
              </span>
            </div>
          </div>
        </template>

        <div
          v-if="rowData.length == 0"
          class="d-flex h-100 w-100 flex-column align-items-center justify-content-center"
          style="min-height: 20vh"
        >
          <h2 v-if="loading > 0">{{ $t("lables.loading") + "..." }}</h2>
          <h2 v-else>{{ $t("lables.noData") }}</h2>
        </div>
        <base-table
          :key="tableKey"
          v-if="!resize && permissions && rowData.length > 0"
          ref="listTable"
          :row="rowData"
          :columnDefs="column"
          :settings="settings"
          :entity="entity"
          :entityPermission="permissions"
          :height="height"
          @showRowBtnClick="onShowRowBtnClick"
          @editRowBtnClick="() => {}"
          @deleteRowBtnClick="() => {}"
        ></base-table>
      </base-card>
    </div>
  </div>
  <base-modal
    v-model="showInstanceDetail"
    :showOwerlay="true"
    :contentWidth="'850px'"
    :currentPath="currentPath"
    :previsionPath="previsionPath"
  >
    <monitor-card
      :item="instanceObject"
      @close-modal="closeInstanceCardModal"
      :refresh="autorefresh"
    >
    </monitor-card>
  </base-modal>

  <spinner v-if="loading > 0"></spinner>
</template>
<script>
// import BaseCheckbox from "@/components/BaseCheckbox.vue";

import BaseTable from "@/components/BaseTable.vue";
import BaseCard from "@/components/BaseCard.vue";
import BaseModal from "@/components/BaseModal.vue";
//import InstanceCard from "@/pages/entityPages/InstanceCard.vue";
import monitorCard from "@/pages/instancePages/card_monitor.vue";
import Spinner from "@/components/Spinner.vue";
import Settings from "@/components/dashboard/Settings.vue";

import { mapActions, mapState } from "pinia";
import { useDashboardStore } from "@/stores/dashbordStore";
import EntityApi from "@/api/entityApi";

import { hasProperty } from "@/helpers";
import { computed } from "vue";

export default {
  name: "Monitoring",
  props: {},
  inject: [],
  components: {
    BaseTable,
    BaseCard,
    BaseModal,
    monitorCard,
    Spinner,
    Settings,
    // BaseCheckbox,
  },
  data() {
    return {
      spinnerShow: true,
      entityFields: null,
      instanceObject: null,
      instanceCardType: "edit",
      column: [],
      rowData: [],
      settings: {},
      showInstanceDetail: false,
      refreshRow: null,
      entity: "",
      currentPath: null,
      previsionPath: null,
      entitys: null,
      tableKey: 1,

      loading: 0,

      arrayNames: ["conveniq_name", "date"],
      width_spec: 140,

      //filters
      settingListDevice: [],

      permissions: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },

      height: 0,
      resize: false,

      autorefresh: true,
      minutes: 5,
      timerAutoRefresh: null,
      lastUpdateDate: new Date(),
    };
  },
  watch: {
    autorefresh(newVal) {
      if (!newVal) {
        clearTimeout(this.timerAutoRefresh);
        this.timerAutoRefresh = null;
      } else {
        this.getAutoData();
      }
    },
    lang() {
      this.getDataMonitoring();
    },
  },
  computed: {
    ...mapState(useDashboardStore, ["settingDashboard"]),
    titleRefresh() {
      return "lables.refrdata";
      // if (this.autorefresh) {
      //   return "lables.autorefresh";
      // } else {
      //   return "lables.refrdata";
      // }
    },
    lastUpdate() {
      const now = this.lastUpdateDate;
      const format = (n) => n.toString().padStart(2, "0");

      return `${format(now.getDate())}.${format(now.getMonth() + 1)} ${format(
        now.getHours()
      )}:${format(now.getMinutes())}`;
    },
    lang() {
      return this.$i18n.locale;
    },
  },
  methods: {
    ...mapActions(useDashboardStore, [
      "getSettingsDashboard",
      "setSettingDashboard",
    ]),
    cukle() {
      this.timerAutoRefresh = setTimeout(() => {
        this.lastUpdateDate = new Date();
        clearTimeout(this.timerAutoRefresh);
        this.timerAutoRefresh = null;

        this.getDataMonitoring();
        this.cukle();
      }, this.minutes * 60 * 1000);
    },
    getAutoData() {
      this.lastUpdateDate = new Date();
      if (this.autorefresh) {
        clearTimeout(this.timerAutoRefresh);
        this.timerAutoRefresh = null;

        this.getDataMonitoring();
        this.cukle();
      } else {
        this.getDataMonitoring();
      }
    },
    onShowRowBtnClick(row) {
      console.log("onShowRowBtnClick", row.fulldata);
      clearTimeout(this.timerAutoRefresh);
      this.timerAutoRefresh = null;

      this.instanceObject = row;
      this.instanceCardType = "show";
      this.showInstanceDetail = true;
    },
    closeInstanceCardModal() {
      const modalId = this.entitys;
      window.history.pushState({ modalId }, null, this.previsionPath);
      this.showInstanceDetail = false;
      this.instanceObject = {};

      console.log("close inst card");

      if (this.autorefresh) {
        this.getAutoData();
      }
    },
    dtFormat(dt) {
      function convertDateForIos(date) {
        if (typeof date == "string") {
          let arr = date.split(/[- :]/);
          let dt2 = new Date(
            arr[0],
            arr[1] - 1,
            arr[2],
            arr[3],
            arr[4],
            arr[5]
          );
          return dt2;
        } else {
          return date;
        }
      }

      if (dt != "" && dt != null) {
        let d = null;
        if (typeof dt == "string") {
          if (dt.indexOf("T") > -1) {
            let arr = dt.split("T");
            let dataT = arr[0] + " " + arr[1];
            d = convertDateForIos(dataT);
          } else {
            d = convertDateForIos(dt);
          }

          let dtn = +convertDateForIos(d);
          if (dtn < 0) {
            return null;
          }
        } else if (typeof dt == "number") {
          d = new Date(dt);
        } else {
          d = new Date(dt);
        }

        let obj = {
          year: d.getFullYear(),
          month: d.getMonth() + 1,
          day: d.getDate(),
          hours: d.getHours(),
          min: d.getMinutes(),
          sec: d.getSeconds(),
          int: new Date(d).getTime(),
        };

        return obj;
      } else {
        return dt;
      }
    },
    numberComparator: (valueA, valueB) => {
      if (valueA == valueB) return 0;
      return valueA > valueB ? 1 : -1;
    },
    dateComparator: (valueA, valueB) => {
      if (!valueA && !valueB) {
        return 0;
      }
      if (!valueA) {
        return -1;
      }
      if (!valueB) {
        return 1;
      }
      let strToUnix = (str) => {
        const [dateRelated, timeRelated] = str.split(" ");
        const [day, month, year] = dateRelated.split(".");
        const [hours, minutes, seconds] = timeRelated.split(":");
        return new Date(
          +year,
          month - 1,
          +day,
          +hours,
          +minutes,
          +seconds
        ).getTime();
      };
      let cellValueA = strToUnix(valueA);
      let cellValueB = strToUnix(valueB);
      if (cellValueA === null && cellValueB === null) {
        return 0;
      }
      if (cellValueA === null) {
        return -1;
      }
      if (cellValueB === null) {
        return 1;
      }
      return cellValueA - cellValueB;
    },
    generateColumnItem(itemObg, item) {
      //console.log('generateColumnItem',itemObg, item);
      let newItem = {
        field: item,
        minWidth: 100,
      };
      newItem = { ...newItem, ...itemObg };
      if (this.arrayNames.includes(item)) {
        newItem.minWidth = this.width_spec;
      }
      if (hasProperty(itemObg, "format")) {
        switch (itemObg.format) {
          case "date-time":
            newItem.cellRenderer = "dateRenderer";
            newItem.editable = false;
            newItem.comparator = this.dateComparator;
            newItem.enablePivot = true;
            newItem.enableRowGroup = true;
            newItem.filter = "agDateColumnFilter";
            newItem.pivot = false;
            newItem.resizable = true;
            newItem.rowGroup = false;
            newItem.sortable = true;
            newItem.filterParams = {
              browserDatePicker: true,
              buttons: ["reset"],
              comparator: (filterLocalDateAtMidnight, cellValue) => {
                if (cellValue == null) return -1;
                let cellDate = this.dtFormat(cellValue);
                let filterVal = this.dtFormat(filterLocalDateAtMidnight);
                if (
                  cellDate.day == filterVal.day &&
                  cellDate.month == filterVal.month &&
                  cellDate.year == filterVal.year
                ) {
                  return 0;
                }
                if (cellDate.int <= filterVal.int) {
                  return -1;
                }
                if (cellDate.int >= filterVal.int) {
                  return 1;
                }
              },
            };
            break;
          default:
            break;
        }
      }
      if (hasProperty(itemObg, "type")) {
        switch (itemObg.type) {
          case "boolean":
            newItem.cellRenderer = "boolRendRounded";
            break;
          case "number":
            newItem.sortable = true;
            newItem.filter = "agNumberColumnFilter";
            newItem.pivot = false;
            newItem.enablePivot = true;
            newItem.enableRowGroup = true;
            newItem.rowGroup = false;
            newItem.resizable = true;
            newItem.editable = false;
            newItem.comparator = this.numberComparator;
            break;
          default:
            break;
        }
      }
      return newItem;
    },
    confirmSetting(payload) {
      this.lastUpdateDate = new Date();
      switch (payload.event) {
        case "dropdown":
          this.setSettingDashboard({
            device: payload.listSelectedNames,
            device_id: payload.listSelected,
            item: [],
            item_id: [],
          });

          this.getDataMonitoring();

          break;
        case "clear":
          this.clearSettings();
          break;
        default:
          break;
      }
    },
    clearSettings() {
      this.settingListDevice.map((item) => {
        item.checked = false;
      });

      this.setSettingDashboard({
        device: [],
        device_id: [],
        item: [],
        item_id: [],
      });

      this.getDataMonitoring();
    },
    async setDataSettings() {
      this.getSettingsDashboard().then((data) => {
        const device = data.conveniq.reduce((acc, item) => {
          const dev = item.name.trim();
          if (!acc[dev]) {
            acc[dev] = {
              id: item.id,
              checked: false,
              title: dev,
            };
          }
          return acc;
        }, []);

        this.settingListDevice = Object.values(device);
      });
    },
    translState(word) {
      return this.$t("monitoring.data.state." + word);
    },
    async getDataMonitoring() {
      // {conveniq_id: []}
      //this.getMonitoring()
      let dataRequest = { conveniq_id: this.settingDashboard.conveniq_id };
      this.rowData = [];
      this.column = [];

      this.loading++;

      this.$nextTick(() => {
        this.resizeAGgridcont();
      });

      this.dataFull = await EntityApi.getMonitoring(dataRequest);

      this.column.push({
        pinned: "left",
        lockPinned: true,
        sortable: false,
        headerName: "",
        minWidth: 35,
        maxWidth: 35,
        suppressMenu: false,
        filter: false,
        editable: false,
        cellRenderer: "rowMenuCellRenderer",
        checkboxSelection: false,
        headerCheckboxSelection: false,
      });

      this.column.push(
        this.generateColumnItem(
          {
            headerName: `${this.$t("monitoring.header.name")}`,
            headerValueGetter: () => this.$t("monitoring.header.name"),
            pinned: "left",
            lockPinned: true,
          },
          "conveniq_name"
        )
      );
      this.column.push(
        this.generateColumnItem(
          {
            headerName: `${this.$t("monitoring.header.state")}`,
            headerValueGetter: () => this.$t("monitoring.header.state"),
            pinned: "left",
            lockPinned: true,
          },
          "state"
        )
      );

      this.column.push(
        this.generateColumnItem(
          {
            headerName: `${this.$t("monitoring.header.address")}`,
            headerValueGetter: () => this.$t("monitoring.header.address"),
            minWidth: 200,
          },
          "conveniq_address"
        )
      );
      //this.column.push(this.generateColumnItem({headerName:"Connection"},"connection"));
      this.column.push(
        this.generateColumnItem(
          {
            headerName: `${this.$t("monitoring.header.model")}`,
            headerValueGetter: () => this.$t("monitoring.header.model"),
          },
          "conveniq_model"
        )
      );

      this.column.push({
        ...this.generateColumnItem(
          {
            headerName: `${this.$t("monitoring.header.status")}`,
            headerValueGetter: () => this.$t("monitoring.header.status"),
            type: "boolean",
            minWidth: 60,
          },
          "status"
        ),
        /*...{
          cellStyle: (params) =>
            params.data.status != undefined
              ? params.data.status
                ? { backgroundColor: "rgba(100, 223, 2, 0.8)" }
                : { backgroundColor: "rgba(255, 32, 0, 0.65)" }
              : { backgroundColor: "rgba(119,119,119,0.7)" },
        },*/
        ...{ cellClass: "d-flex justify-content-center align-items-center" },
      });
      this.column.push(
        this.generateColumnItem(
          {
            format: "date-time",
            headerName: `${this.$t("monitoring.header.status_date")}`,
            headerValueGetter: () => this.$t("monitoring.header.status_date"),
          },
          "date"
        )
      );

      let data_keys = ["status"],
        dataR = [];
      let rowd = null;
      let ind = 0;
      this.dataFull.map((item) => {
        rowd = item;
        rowd.id = ind;

        if (item.data) {
          rowd = { ...rowd, ...item.data };
          // for (let key in item.data) {
          //   if (!data_keys.includes(key)) {
          //     data_keys.push(key);
          //     this.column.push({
          //       ...this.generateColumnItem(
          //         { headerName: key, type: "boolean", minWidth: 150 },
          //         key
          //       ),
          //       ...{
          //         cellStyle: (params) =>
          //           params.data[key] != undefined
          //             ? params.data[key]
          //               ? { backgroundColor: "rgba(100, 223, 2, 0.8)" }
          //               : { backgroundColor: "rgba(255, 32, 0, 0.65)" }
          //             : { backgroundColor: "rgba(119,119,119,0.7)" },
          //       },
          //       ...{
          //         cellClass: "d-flex justify-content-center align-items-center",
          //       },
          //     });
          //   }
          // }
          delete rowd.data;
        }
        if (rowd.state) {
          rowd.state = this.translState(rowd.state);
        }
        dataR.push(rowd);
        ind++;
      });

      ind = 0;
      dataR.map((item) => {
        data_keys.map((key) => {
          if (item[key] && item[key].status != undefined) {
            item[key] = {
              value: item[key].status,
              data: item[key].data,
              //title: "text",
            };
          } else {
            item[key] = { value: null, data: null };
          }
        });
        item.fulldata = this.dataFull[ind];
        ind++;

        this.rowData.push(item);
      });

      this.loading--;
    },

    resizeAGgridcont() {
      this.resize = true;

      this.$nextTick(() => {
        let cont = document.getElementById("cardMonitoring");
        if (cont) {
          let elem = cont.querySelector("div#basecard_container");
          if (elem) {
            let header = elem.querySelector("div.v-card-item");

            if (header) {
              header = header.clientHeight;
            } else {
              header = 0;
            }
            this.height = elem.clientHeight - header - 24;
          }
        }
        this.resize = false;
      });
    },
  },
  provide() {
    return {
      refreshRow: computed(() => this.refreshRow),
    };
  },
  created() {},
  mounted() {
    this.entitys = this.$route?.params.entitys;
    this.previsionPath = this.$route?.fullPath;

    this.setDataSettings();
    this.getDataMonitoring();

    window.addEventListener("resize", this.resizeAGgridcont);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resizeAGgridcont);
  },
};
</script>
<style scoped>
.df-preview-wrapper {
  box-shadow: none !important;
}
.center_bool {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
