<template>
  <div>Test</div>
  <div v-if="$can('edit', 'User')">
    <a href="/">Do it</a>
  </div>
  <div>
    <a class="v-btn" @click="addNewAbility" rel="noopener noreferrer"
      >addNewAbility</a
    >
  </div>
  <!-- <base-tabs active-tab-color="#9b59b6" active-text-color="white"> -->
    <!-- <base-tab title="First tab"> First tab content </base-tab>
    <base-tab title="Second tab"> First tab content </base-tab> -->
  <base-tabs v-model="activeTab" :tabsList="tabsList">
     <base-tab :title="activeTab" > 
     First tab content {{activeTab}}
     </base-tab>
  </base-tabs>
   <div>
    <a class="v-btn" @click="showDialog = true" rel="noopener noreferrer"
      >ShowDialog</a
    >
  </div>
  <base-dialog 
  v-model="showDialog"  
  contentWidth='500px'
  :showOwerlay="true"
  >
  <h2>{{$t("lables.confirmDelete")}}</h2>
  <div class="">
    
  </div> 
  </base-dialog>  
  <base-switcher
    :value="boolValue"
    :disabled="true"
  ></base-switcher>
   <base-multiselect
      class="base-multiselect__item"
      :placeholder="`Select`"
      :value="selectValue"
      :items="selectOptions"
      :disabled="true"
      :readonly="true"
      :canClear="true"
      :closeOnSelect="true"
      @select="selectItem"
    ></base-multiselect>
</template>
<script>
import BaseTabs from "@/components/BaseTabs.vue";
import BaseTab from "@/components/BaseTab.vue";
import BaseDialog from "@/components/BaseDialog.vue";
import BaseMultiselect from "@/components/BaseMultiselect.vue";
import { AbilityBuilder, Ability } from "@casl/ability";
import BaseSwitcher from "@/components/BaseSwitcher";
export default {
  name: "test page",
  props: {},
  components: { BaseTabs, BaseTab, BaseDialog, BaseSwitcher, BaseMultiselect },
  data() {
    return {
      activeTab:"Second tab",
      tabsList:[
        "First tab",
        "Second tab"
      ],
      showDialog:false,
      boolValue:true,
      selectValue:"Test",
      selectOptions:[
        "Test", "Test 1", "Test 12"
      ]
    };
  },
  methods: {
    addNewAbility() {
      const { can, rules } = new AbilityBuilder(Ability);
      can("edit", "User");
      this.$ability.update(rules);
    },
    selectItem(value){
      console.log(value);
    }
  },
  mounted() {},
};
</script>
<style lang="scss"></style>
