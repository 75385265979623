import { defineStore } from "pinia";
// import axios from "axios";
// import jwt_decode from "jwt-decode";
import userApi from "@/api/userApi";
// const baseURL = process.env.VUE_APP_API_URL;

export const useUserStore = defineStore("user", {
  state: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    token: localStorage.getItem("token"),
    csrf: localStorage.getItem("csrf"),
    returnUrl:null,

    entities: null,
  }),

  getters: {
    isAuthenticated:(state) => state.token && state.user ? true : false,
    userRoles:(state) => state.user?.roles ? state.user.roles : null,
    isAdmin:(state) => state.user?.roles && state.user.roles.includes('superuser') ? true : false,
  },
  actions: {   
    setToken(token) {      
      this.token = token;
      localStorage.setItem("token", token);
    },    
    setReturnUrl(returnUrl) {
      this.returnUrl = returnUrl;
    },
    setUser(data) {
      this.user = data;
      localStorage.setItem("user", JSON.stringify(data));
    },
    goToPrevUrl(){
      if (this.returnUrl) {
        this.router.push(this.returnUrl);
      } else this.router.push("/");
    },
    addEntity(value, entity){ 
      let check = false;
      if(!this.entities){
        this.entities = {[entity]: value};
        check = true;
      }
      if ( check || !Object.prototype.hasOwnProperty.call(this.entities, entity) ){
        this.entities[entity] = value;
        localStorage.setItem('entities', JSON.stringify(this.entities) );
      }
    },
    checkEntities(){
      this.entities = JSON.parse(localStorage.getItem('entities'));
    },
    checkEnitty(entity){
      if( this.entities && this.entities[entity] ){
        return this.entities[entity];
      } else {
        return null;
      }
    },

    logout: function () {
      this.router.push({
        name: "Login"
      });
      this.user = null;
      this.token = null;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("entities");
      userApi.logout();
    }   
  },
});
