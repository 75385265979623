<template>
  <div
    class="d-flex justify-content-between flex-row align-center top-panel mx-2"
  >
    <div class="d-flex align-center">
      <h2>{{ schema?.title }}</h2>
    </div>
    <div class="d-flex align-center" v-if="isModal">
      <base-button
        @click="closeModal"
        className="v-btn v-theme--BLUE_THEME bg-error v-btn--density-default v-btn--size-default v-btn--variant-flat mr-2"
        v-tooltip:bottom="$t('buttons.cansel')"
        >{{ $t("buttons.cansel") }}
      </base-button>
      <base-button
        :variant="'pure'"
        className="v-btn v-btn--flat v-theme--BLUE_THEME bg-primary v-btn--density-default v-btn--size-default v-btn--variant-elevated ml-auto"
        aria-haspopup="menu"
        aria-expanded="false"
        aria-owns="v-menu-669"
        @click="saveInstance"
        v-tooltip:bottom="$t('buttons.saveChanges')"
        v-if="activeTab === 'Detail' && cardType !== 'show'"
      >
        {{ $t("buttons.save") }}
      </base-button>
    </div>
  </div>
  <base-tabs
    v-if="tabsList.length > 1"
    v-model="activeTab"
    :tabsList="tabsList"
    :instanceId="item.id"
    @change="onTabChange"
  >
  </base-tabs>
  <div
    class="tabs-content d-flex flex-column"
    style="flex: 1 0 auto"
    ref="tabsContent"
    :style="setStyleModal"
  >
    <div ref="dinamicFormContainer">
      <dinamic-form
        v-if="formShema && activeTab === 'Detail'"
        :schema="formShema"
        :data="formData"
        @model-updated="updateInstanceData"
        @validation-error="onValidationError"
        @get-form-height="onSetFormHeight"
        ref="dinamicForm"
      >
      </dinamic-form>
    </div>
    <instance-card-table
      v-if="activeTabEntity"
      :tableEntity="activeTabEntity"
      :subtableSchema="subtableSchema"
      :parrentEntityId="formData.id"
      :cardType="cardType"
    ></instance-card-table>
    <component
      v-if="activeEntityModul"
      :is="activeEntityModul"
      :id="item.id"
      :data="item"
      @get-form-height="onSetFormHeight"
      @close-modalf="closeModal"
    ></component>
    <spinner v-if="spinnerShow"></spinner>
  </div>
</template>
<script>
import { isObject, hasProperty } from "@/helpers";
import { mapState } from "pinia";
import { useUserStore } from "@/stores/userStore";

import DinamicForm from "@/components/formGenerator/DinamicForm.vue";
import BaseTabs from "@/components/BaseTabs.vue";
import InstanceCardTable from "@/pages/entityPages/InstanceCardTable.vue";
import BaseButton from "@/components/BaseButton.vue";
import Analizator from "@/components/instanceModules/Analizator.vue";
import DrawZones from "@/components/instanceModules/DrawZones.vue";
import Advertising from "@/components/instanceModules/Advertizing.vue";
import MlServerCredentials from "@/components/instanceModules/MlServerCredentials.vue";
import GetRecognizedPhoto from "@/components/instanceModules/MlServer_recPhoto.vue";
import AutoAnnotation from "@/components/instanceModules/MlServer_autoanno.vue";
import EntityApi from "@/api/entityApi";
import Spinner from "../../components/Spinner.vue";
export default {
  name: "InstanceCard",
  props: {
    entity: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
    },
    schema: {
      type: Object,
    },
    cardType: {
      type: String,
      default: "edit",
    },
    defaultValues: {
      type: Object,
    },
    isModal: {
      type: Boolean,
      default: true,
    },
    formdinamic: {
      type: Boolean,
      default: true,
    },
  },
  inject: [],
  emits: ["inctanseDataUpdate", "inctanseCreate", "closeModal"],
  components: {
    Spinner,
    DinamicForm,
    BaseButton,
    BaseTabs,
    InstanceCardTable,
    Analizator,
    DrawZones,
    MlServerCredentials,
    GetRecognizedPhoto,
    AutoAnnotation,
    Advertising,
  },
  data() {
    return {
      spinnerShow: false,
      activeTab: "Detail",
      noDetailEntit: ["monitoring"],
      formShema: [],
      formData: null,
      formError: null,
      buildInModules: [],
      dinamicFormHeight: 0,
      heightMult: 0.9,

      filesNotSaved: [],
    };
  },
  watch: {
    activeTab: {
      handler() {
        this.removeNotSaved();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(useUserStore, ["user"]),
    tabsList() {
      let tablist = [];

      if (this.schema?.table_fields && this.schema.table_fields.length > 0) {
        this.schema?.table_fields.forEach((element) => {
          tablist.push(this.schema?.properties[element]?.title);
        });
      }

      if (
        hasProperty(this.schema, "built_in_modules") &&
        this.schema.built_in_modules?.length > 0
      ) {
        this.schema.built_in_modules.forEach((el) => {
          if (el.position === "tabs") {
            tablist.push(el.label);
            this.buildInModules.push(el);
          }
        });
      }

      if (tablist.length > 0 && !this.noDetailEntit.includes(this.entity)) {
        tablist.unshift("Detail");
      }

      return tablist;
    },
    activeEntityModul() {
      if (this.buildInModules.length > 0) {
        let modul = this.buildInModules.find(
          (el) => el.label === this.activeTab
        );
        if (modul) {
          return modul.component;
        } else return null;
      } else return null;
    },
    activeTabEntity() {
      if (this.activeTab.length > 0) {
        const activeTabObject = Object.entries(this.schema.properties).find(
          (el) => el[1].title === this.activeTab
        );
        if (activeTabObject) {
          return activeTabObject[1];
        } else return null;
      } else return null;
    },
    subtableSchema() {
      if (this.activeTabEntity) {
        let entityName = this.activeTabEntity?.items?.$ref.slice(14);
        return this.schema?.definitions[entityName];
      } else return null;
    },
    setStyleModal() {
      if (this.isModal) {
        const windowHeight = window.innerHeight;
        const contentModalHeight = windowHeight * this.heightMult;
        return {
          height: `${contentModalHeight - 20}px`,
          overflow: "auto",
        };
      }

      return {
        overflow: "hidden",
      };
    },
  },
  methods: {
    generateNewSchemaElement(item, property) {
      // console.log("item", item);
      // console.log("property", property);
      let newElement = {
        columns: {
          container: 6,
        },
      };
      newElement.id = item;
      newElement.name = item;
      newElement.label = property.title;

      switch (property.type) {
        case "string":
          newElement.type = "text";
          break;
        case "integer":
          newElement.type = "text";
          newElement.inputType = "number";
          break;
        case "number":
          newElement.type = "text";
          newElement.inputType = "number";
          break;
        case "boolean":
          newElement.type = "toggle";
          break;
        case "object":
          newElement.type = "json";
          break;
        default:
          break;
      }

      if (hasProperty(property, "$ref")) {
        let entityName = property.$ref.slice(14);
        let entity = this.schema?.definitions[entityName];
        newElement.type = "multiselect";
        if (hasProperty(entity, "model")) {
          newElement.items = {
            entity: entity?.model,
            valueName: "id",
            titleName: property.title_field_name
              ? property.title_field_name
              : "name",
          };

          newElement.object = true;
        }
        if (hasProperty(entity, "enum")) {
          newElement.items = entity.enum;
        }
      }
      if (hasProperty(property, "format")) {
        switch (property.format) {
          case "date-time":
            newElement.type = "datetime";
            break;
          default:
            break;
        }
        // console.log(property.format);
      }
      if (this.schema?.required && this.schema?.required.includes(item)) {
        newElement.rules = ["required"];
      }
      if (
        this.schema?.readonly_fields &&
        (this.schema?.readonly_fields.includes(item) ||
          this.cardType === "show")
      ) {
        newElement.readonly = true;
        newElement.disabled = true;
      }

      if (hasProperty(newElement, "type")) {
        return newElement;
      } else return null;
    },
    generateNewFileElement(item, property) {
      let newElement = {
        type: "file",
        id: item,
        name: item,
        label: property.title,
        fileType: property?.type,
        linkType: property?.link_type,
        columns: {
          container: 6,
        },
      };
      return newElement;
    },
    isFileField(field) {
      return this.schema?.file_fields.includes(field) ? true : false;
    },
    generateSchema(entityFields) {
      if (entityFields && isObject(entityFields.properties)) {
        let schema = [];
        for (let key in entityFields.properties) {
          if (!this.isFileField(key)) {
            let el = this.generateNewSchemaElement(
              key,
              entityFields.properties[key]
            );
            if (el) {
              schema.push(el);
            }
          } else {
            schema.push(
              this.generateNewFileElement(key, entityFields.properties[key])
            );
          }
        }
        // console.log(schema);
        return schema;
      }
    },

    removeNotSaved() {
      if (this.filesNotSaved.length > 0) {
        this.filesNotSaved.map((ff) => {
          EntityApi.sendCustomRequest(
            "/delete-file?file_name=" + ff,
            "post",
            null
          ).then(() => {
            const idx = this.filesNotSaved.findIndex((fileI) => fileI == ff);
            if (idx > -1) {
              this.filesNotSaved.splice(idx, 1);
            }
          });
        });
      }
    },
    updateInstanceData(fieldName, newValue) {
      //console.log("updateInstanceData", fieldName, typeof newValue, newValue);

      //remove not saved files
      const indx = this.formShema.findIndex(
        (field) => field.type == "file" && field.name == fieldName
      );
      if (newValue && indx > -1) {
        this.filesNotSaved.push(newValue);
      }

      this.formData[fieldName] = newValue;
    },
    async saveInstance() {
      this.validateForm();

      this.$nextTick(() => {
        if (this.formError) {
          console.log("form has errors");
        } else {
          switch (this.cardType) {
            case "create":
              EntityApi.createEntityItem(this.entity, this.formData).then(
                (res) => {
                  if (res) {
                    this.filesNotSaved = [];
                    this.$emit("inctanseCreate", res);
                    this.$notify(
                      {
                        group: "alert",
                        type: "info",
                        title: this.$t("lables.success"),
                        text: this.$t("lables.saved"),
                      },
                      3000
                    );
                  } else {
                    this.$notify(
                      {
                        group: "alert",
                        type: "error",
                        title: this.$t("lables.error"),
                        text: this.$t("lables.saved_not"),
                      },
                      3000
                    );
                  }
                }
              );

              break;
            default:
              EntityApi.updateEntityItem(this.entity, this.formData).then(
                (res) => {
                  if (res) {
                    this.filesNotSaved = [];

                    this.$notify(
                      {
                        group: "alert",
                        type: "info",
                        title: this.$t("lables.success"),
                        text: this.$t("lables.saved"),
                      },
                      3000
                    );
                    this.$emit("inctanseDataUpdate", this.formData);
                  } else {
                    this.$notify(
                      {
                        group: "alert",
                        type: "error",
                        title: this.$t("lables.error"),
                        text: this.$t("lables.saved_not"),
                      },
                      3000
                    );
                  }
                }
              );
              break;
          }
        }
      });
    },
    closeModal() {
      this.$emit("closeModal");
    },
    onTabChange(tab) {
      if (this.item?.id) {
        this.activeTab = null;
        this.activeTab = tab;
      } else {
        this.validateForm();
      }
    },
    validateForm() {
      this.formError = null;
      this.$refs.dinamicForm.validateForm();
    },
    onValidationError(errors) {
      this.formError = errors;
    },
    setScroll() {
      const windowHeight = window.innerHeight;
      const contentModalHeight = windowHeight * this.heightMult;

      let dinamicFormHeight;
      /*if (this.dinamicFormHeight === 0) {
        const dinamicForm = this.$refs.dinamicFormContainer;
        dinamicFormHeight = dinamicForm.clientHeight;
      } else {
        dinamicFormHeight = this.dinamicFormHeight;
      }*/
      dinamicFormHeight = this.$refs.dinamicFormContainer.clientHeight;

      if (dinamicFormHeight > contentModalHeight) {
        const tabsContent = this.$refs.tabsContent;
        tabsContent.style.overflow = "auto";
      }
    },
    onSetFormHeight(params) {
      this.dinamicFormHeight = params;
      this.setScroll();
    },
  },
  created() {
    this.formShema = this.generateSchema(this.schema);
    this.formData = this.item;
    if (this.defaultValues) {
      this.formData = { ...this.formData, ...this.defaultValues };
    }
    // if( this.schema.table_fields && Array.isArray(this.schema.table_fields) && this.schema.table_fields.length > 0) {
    //   this.activeTab = this.schema.properties[this.schema.table_fields[0]].title;
    // }
  },
  mounted() {
    console.log("InstanceCard mounted");
    this.setScroll();
  },
  beforeUnmount() {
    this.removeNotSaved();
  },
};
</script>
<style scoped>
.top-panel {
  padding-top: 6px;
  padding-left: 20px;
}

.item-id {
  font-size: 12px;
  color: rgb(175, 174, 174);
}

.show-scroll {
  overflow: auto;
}

.hide-scroll {
  overflow: hidden;
}
</style>
