<template>
  <div class="d-flex flex-column h-100 w-100 align-items-start justify-content-start">
    <button
        v-for="command in get_commands_data"
        :key="command.id"
        @click="confirmCommand(command.id)"
        class="v-btn v-theme--ORANGE_THEME bg-primary v-btn--density-default v-btn--size-default v-btn--variant-flat mb-2"
        style="max-width: 270px; margin-left: 15px; margin-top: 15px;"
    >
      {{ $t("commands."+command.id) }}
    </button>

    <div v-if="showModal" class="modal-overlay">
      <div class="modal-content">
        <h4>{{ $t("lables.confirmCommand") }}</h4>
        <div class="button-container">
          <button @click="executeCommand" class="v-btn v-btn--flat v-theme--BLUE_THEME bg-primary v-btn--density-default v-btn--size-default v-btn--variant-elevated">{{ $t("buttons.confirm") }}</button>
          <button @click="cancelCommand" class="v-btn v-btn--flat v-theme--BLUE_THEME bg-primary v-btn--density-default v-btn--size-default v-btn--variant-elevated">{{ $t("buttons.cansel") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EntityApi from "@/api/entityApi";

export default {
  name: "ConveniqCommand",
  props: {
    conveniqId: {
      type: [String],
      required: true
    }
  },
  data() {
    return {
      get_commands_data: [
        { id: 'reboot_pc'},
        { id: 'reboot_terminal'},
      ],
      showModal: false,
      selectedCommand: null,
    };
  },
  methods: {
    confirmCommand(id) {
      this.selectedCommand = id;
      this.showModal = true;
    },
    executeCommand() {
      this.showModal = false;
      this.execCommand(this.selectedCommand)
      this.selectedCommand = null;
    },
    cancelCommand() {
      this.showModal = false;
      this.selectedCommand = null;
    },
    async execCommand(commandId) {
      let equipmentType;
      switch (commandId) {
        case 'reboot_pc':
          equipmentType = 'computer';
          break;
        case 'reboot_terminal':
          equipmentType = 'terminal-stack';
          break;
        default:
          break;
      }
      if (!equipmentType) {
        return;
      }
      let resp = await EntityApi.executeRebootCommand(equipmentType, this.conveniqId);
      if (resp && typeof resp === 'object' && 'status' in resp && resp.status) {
        this.$notify({group: "alert", type:"info", title: this.$t('lables.success'), text: this.$t('lables.successfulExec')}, 3000);
      } else {
        this.$notify({group: "alert", type:"error", title: this.$t('lables.opps'), text: this.$t('lables.failedExec')}, 3000);
      }
    },
  },
};
</script>


<style scoped>

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  z-index: 1001;
}

.button-container {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

</style>