import { createRouter, createWebHistory } from 'vue-router'
import Home from '../pages/Home.vue'
import Test from '../pages/Test.vue'
import Entitys from '../pages/entityPages/EntityList.vue';
import Monitoring from "../pages/instancePages/Monitoring.vue"
import { useUserStore } from "@/stores/userStore";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/test',
        name: 'Test',
        component: Test,
    },
    {
        path: '/user/login',
        name: 'Login',
        meta : {
            template : 'landing',
            public: true
        },
        component: () => import(/* webpackChunkName: "login" */ '../pages/auth/login/Login.vue')
    },
    {
        path: '/404',
        name: '404',
        meta : {
            template : 'landing',
            public: true
        },
        component: () => import(/* webpackChunkName: "404" */ '../pages/404.vue')
    },
    {
        path: '/user/register',
        name: 'Register',
        meta : {
            template : 'landing',
            public: true
        },
        component: () => import(/* webpackChunkName: "register" */ '../pages/auth/login/Login.vue')
    },
    {
        path: '/:entitys/list',
        name: 'Entitys',
        component: Entitys,
    },
    {
        path: '/monitoring',
        name: 'Monitoring',
        component: Monitoring,
    },
    {
        path: '/details/:entity/:id',
        name: 'EntityDetails',
        component: () => import(/* webpackChunkName: "dashboard" */ '../pages/entityPages/EntityDetails.vue')
    },
    {
        path: '/external/:id',
        name: 'external',
        component: () => import(/* webpackChunkName: "external" */ '../pages/ExternalPage.vue')
    },
    {
        path: '/dashboard/:name',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../pages/Dashboard.vue')
    },
    {
        path: '/test-table',
        name: 'TestTable',
        component: () => import(/* webpackChunkName: "dashboard" */ '../pages/TestTable.vue')
    },
    { path: '/:pathMatch(.*)*', redirect: { name: '404' }}
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to?.meta?.public) {
        next()
    }
    else {
        const userStore = useUserStore();
        if(!userStore.isAuthenticated){
            next('/user/login')
        }
        else next()
    }
});

export default router
