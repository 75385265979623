<template>
  <div class="d-flex flex-row w-100">
    <span class="d-flex justify-content-center align-items-center w-100">
      <span
        class="d-flex justify-content-center align-items-center w-100"
        v-if="value_show == null"
        >{{ $t("lables.noData") }}</span
      >

      <div class="d-flex" v-if="typeVal == 'boolean'">
        <span
          class="rounded-div"
          :style="{
            backgroundColor:
              value_show == true
                ? 'rgba(100, 223, 2, 1)'
                : 'rgba(255, 32, 0, 1)',
          }"
        ></span>
      </div>

      <div class="d-flex flex-column w-100" v-if="typeVal == 'array'">
        <div
          class="d-flex justify-content-center align-items-center"
          v-if="value_show.length < 1"
        >
          {{ $t("lables.noData") }}
        </div>
        <div
          class="d-flex flex-row justify-content-between"
          v-for="(item, ind) in value_show"
          :key="ind"
        >
          <div
            :style="[{ 'width:60%;': item.status }]"
            class="d-flex overflow-txt"
          >
            {{ item.label ? translateLabel(item.label) : item.name }}
          </div>
          <div
            v-if="item.status"
            style="width: 40%; justify-content: flex-end"
            class="d-flex"
          >
            <bool-round :value="item.status"></bool-round>
          </div>
        </div>
      </div>

      <span
        class="d-flex"
        v-if="typeVal == 'string' || typeVal == 'object' || typeVal == 'number'"
      >
        {{
          $te("monitoring.labels." + value_show)
            ? $t("monitoring.labels." + value_show)
            : value_show
        }}
      </span>
    </span>
  </div>
</template>
<script>
export default {
  name: "BoolRounded",
  components: {},
  props: {
    value: {
      type: [Boolean, String, Array, Object],
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    translateLabel(text) {
      return text.split(' ').map((word, index) => {
        let translated = this.$te(`monitoring.labels.${word.toLowerCase()}`)
            ? this.$t(`monitoring.labels.${word.toLowerCase()}`).toLowerCase()
            : word.toLowerCase();

        return index === 0 ? translated.charAt(0).toUpperCase() + translated.slice(1) : translated;
      }).join(' ');
    }
  },
  watch: {},
  computed: {
    value_show() {
      // if (["string", "boolean"].includes(this.typeVal)) {
      //   return this.value;
      // } else if (this.typeVal == "array") {
      //   return this.value;
      // } else {
      return this.value;
      // }
    },
    typeVal() {
      if (Array.isArray(this.value)) {
        return "array";
      }
      return typeof this.value;
    },
  },
  mounted() {},
};
</script>

<style>
.text-grey {
  color: rgb(218, 221, 223);
}
.rounded-div {
  width: 1.4rem;
  height: 1.4rem;
  border: 1px solid #000000;
  border-radius: 100%; /* This creates a perfect circle */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.overflow-txt {
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: normal;
  word-break: break-word;
}
</style>
